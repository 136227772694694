// Support component names relative to this directory:
import "@hotwired/turbo-rails";
import * as Sentry from "@sentry/react";

const PRODUCTION_HOSTNAME = "app.cashwise.asia";
const ERROR_PAGE = "/error";

const errorHandlingIntegration = {
  name: "errorHandlingIngtegration",
  setup() {},
  setupOnce() {},
  processEvent(event) {
    if (event.level == "error")
      // when detecting an error, redirect to the error page
      setTimeout(() => {
        window.location.href = ERROR_PAGE;
      }, 1000);
    return event;
  },
};

if (location.hostname === PRODUCTION_HOSTNAME) {
  Sentry.init({
    dsn: "https://6c6f2c0cf1dd84ce3ff4b3868cf83df3@o4506935453548544.ingest.us.sentry.io/4506935466721280",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      errorHandlingIntegration,
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://app.cashwise.asia"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Do not call 'handlerMount()' right after the page is first loaded,
// but when the page is subsequently rewritten by turbo-rails
// to avoid DOMException problems.
// See https://github.com/reactjs/react-rails/issues/1103
var skipFirstCall = false;
ReactRailsUJS.handleEvent("turbo:load", () => {
  skipFirstCall && ReactRailsUJS.handleMount();
  skipFirstCall = true;
});

// ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount)
// ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount)
// ReactRailsUJS.handleEvent('turbo:frame-render', ReactRailsUJS.handleUnmount)
